import React from 'react';

import Layout from '../components/layout';

import Nav from '../components/nav';
import Footer from '../components/footer';
import Slogan from '../components/slogan';
import Contact from '../components/contact';
import SEO from '../components/seo';

import '../scss/attorno.scss';

const WaaromAttorno = () => (
  <Layout>
    <SEO
      title="Foodtruck feesten"
      description="Attorno bakt voor u en uw genodigden pizza’s die enkel in de
      betere pizza-restaurants in Italië worden opgediend. Wij komen tot
      een dergelijk resultaat als gevolg van de combinatie van onze
      rechtstreeks uit Italië geïmporteerde authentieke houtovens en ons
      professioneel opgeleid, klantvriendelijk personeel."
    />

    <Nav />

    <header
      className="intro-block py-3 cover-bg"
      style={{
        backgroundImage: 'url(\'/design/img/header/feest-organiseren.jpg\')',
      }}
    >
      <div className="container">
        <Slogan heading="Foodtruck feesten" />
      </div>
    </header>

    <section
      className="pt-8 pb-3 text-center pattern-bg"
      style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
    >
      <div className="container">
        <div className="row no-dev">
          <div className="col-sm-8 col-sm-offset-2 sep-bottom editContent">
            <h2 className="title">Feest organiseren?</h2>
            <p>
              Attorno bakt voor u en uw genodigden pizza’s die enkel in de
              betere pizza-restaurants in Italië worden opgediend. Wij komen tot
              een dergelijk resultaat als gevolg van de combinatie van onze
              rechtstreeks uit Italië geïmporteerde authentieke houtovens en ons
              professioneel opgeleid, klantvriendelijk personeel.
            </p>
          </div>
          <div className="col-sm-12">
            <img
              src="/design/img/center-block-background.png"
              className="screen"
              alt="Onze Authentieke oven"
            />{' '}
          </div>
        </div>
      </div>
    </section>
    <section
      className="pt-2 pb-5 pattern-bg"
      style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1">
            <h3>Onze ovens</h3>
            <p>
              De Italiaanse authentieke houtovens hebben een stenen vloer.
              Hierdoor kunnen pizza’s gebakken worden die zowel een dunne als
              een krokante bodem hebben. Kort vóór wij op uw event toekomen,
              maken en kneden wij het dagverse deeg. De pizza's worden ook nog
              op voorhand voorgebakken. Ter plaatse, op uw event, worden de
              pizza's pas belegd.
            </p>
            <div className="text-center">
              <img
                src="/design/img/teaser/onze-ovens.jpg"
                alt="Onze zelfontwikkelde houtoven"
              />
            </div>

            <h3>Voorbereiding</h3>
            <p>
              Er worden voor u steeds twaalf verschillende soorten pizza’s
              gemaakt. Hiertoe behoren uiteraard de traditionele pizza’s, maar
              daarnaast maken wij ook echt bijzonder fijne pizza's. Voorbeelden
              voor de traditionele pizza's zijn de hawai, de salami en de
              margherita. Hier is zeker het jongere deel van uw genodigden op
              uit. In onze exclusieve pizza’s worden onder andere
              rivierkreeftjes, pesto, Parmaham, exclusieve Italiaanse kazen en
              nog veel meer verwerkt. Op aanvraag kunnen wij ook voorzien in
              lactose- of glutenvrije pizza’s. Heel veel is mogelijk na overleg!
            </p>
            <div className="text-center">
              <img
                src="/design/img/teaser/pizza-rivierkreeften.jpg"
                alt="Meerdere pizza's"
              />
            </div>

            <h3>Verloop</h3>
            <p>
              Per minuut komen er twee pizza’s, per oven, afgebakken op de
              tafel. De pizza's worden vervolgens in 6 tot 8 stukken gesneden.
              De genodigde neemt een pizzastuk en keert, wanneer hij/zij trek
              heeft in een volgend stukje, terug naar de buffettafel. Het
              pizzafeest is à volonté! Proeven van alle, of toch van een groot
              deel van het aanbod, is dus perfect mogelijk.
            </p>

            <h3>Meer dan pizza?</h3>
            <p>
              Indien gewenst kunnen wij ook voorzien in verrukkelijke voor-
              en/of nagerechten. Bij de voorgerechten is er de keuze tussen een
              koude en een warme variant.
              <br />
              Het koude voorgerecht betreft echte Italiaanse specialiteiten
              zoals olijven, Felino salami, parmegiano, enz…
              <br />
              Het warme voorgerecht is een ciabatta die we besmeren met
              kruidenboters en dan in de oven bakken. Het brood wordt nog belegd
              met verse tomaatjes, mozzarella en kruidenolie. Echt een aanrader!
              <br />
              Als nagerecht kunnen wij vers gemaakte, echte Italiaanse tiramisu
              en chocomousse aanbieden. De keuze is aan u, maar nogmaals, in
              overleg is veel mogelijk.
            </p>
            <div className="text-center">
              <img src="/design/img/teaser/vers.jpg" alt="Voorgerechten" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Contact />

    <Footer />
  </Layout>
);

export default WaaromAttorno;
